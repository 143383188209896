import React, { useEffect, useState } from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faSnapchat,
} from "@fortawesome/free-brands-svg-icons";
import { faMapLocation } from "@fortawesome/free-solid-svg-icons";



function Footer(props) {

  console.log('testt');
  console.log(props.data.facebook_page)



  return (
    <Box
      sx={{
        backgroundColor: "#0C5E5B",
        p: 1,
        marginTop: "10px",
        position: "fixed",
        width: "-webkit-fill-available",
        bottom: 0,
        left: 0,
      }}
      component="footer"
    >
      <Container maxWidth="sm">
        <Typography variant="body2" color="text.secondary" align="center">
          <Grid item xs={12} sm={4}>
            {/* <Typography variant="h6" color="text.primary" gutterBottom>
              Follow Us
            </Typography> */}
            <Link
              href={props.data.facebook_page}
              color="#fff"
              sx={{ fill: "#fff", fontSize: "25px" }}
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </Link>
            <Link
              href={props.data.insta_page}
              color="#fff"
              sx={{
                pl: 1,
                pr: 1,
                fill: "#fff",
                fontSize: "25px",
                marginLeft: "10px",
              }}
            >
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
            <Link
              href={props.data.snapchat_page}
              color="#fff"
              sx={{ fill: "#fff", fontSize: "25px", marginLeft: "10px" }}
            >
              <FontAwesomeIcon icon={faSnapchat} />
            </Link>

            <Link
              href={props.data.location}
              color="#fff"
              sx={{ fill: "#fff", fontSize: "25px", marginLeft: "10px" }}
            >
              <FontAwesomeIcon icon={faMapLocation} />
            </Link>
          </Grid>
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
