import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import Footer from "./Footer";
import "./Dish.css";

import NavbarBack from "./NavbarBack";
import imageMenu from "../image/15022-NPYB8O.jpg";

function Dish() {
  const location = useLocation();
  const dish = location.state;
  console.log('dish: ' , dish)
  const navigate = useNavigate();
  const [deta, setDeta] = useState([]);
    const { lan } = useParams();

  console.log('dish before useEffect')
  useEffect(() => {
    console.log('before dish')
    if (!dish) {
      console.log('dish is  null')
      navigate("/en/restauratns");
    } else {
      console.log('dish is not null')
      const fetchData = () => {
        return fetch("https://dashboard.oyoonbaghdad.com/api/get/info", {
          method: "GET",
          headers: { key: "Plansters2023" },
        })
          .then((response) => response.json())
          .then((data) => {
            setDeta(data.data);
          });
      };
      fetchData();
    }
  }, [dish, navigate]);

  return (
    <div>
      <NavbarBack/>
      {lan == "en" ? (
        dish ? (
          <div className="container-dish">
            <img src={dish.image_url ? dish.image_url : imageMenu} alt="Menu" />
            <h1 className="name">{dish.name_english}</h1>
            <span className="price">{dish.price} IQD</span>
            <p>{dish.description_english}</p>
          </div>
        ) : (
          <p>No dish information available.</p>
        )
      ) : dish ? (
        <div className="container-dish ar">
          <img src={dish.image_url ? dish.image_url : imageMenu} alt="Menu" />
          <h1 className="name">{dish.name}</h1>
          <span className="price">{dish.price} IQD</span>
          <p>{dish.description}</p>
        </div>
      ) : (
        <p>No dish information available.</p>
      )}
      {/* 
      {dish ? (
        <div className="container-dish">
          <img src={imageMenu} alt="Menu" />
          <h1 className="name">{dish.name_english}</h1>
          <span className="price">{dish.price}</span>
          <p></p>
        </div>
      ) : (
        <p>No dish information available.</p>
      )} */}
      <Footer data={deta} />
    </div>
  );
}

export default Dish;
