import './Restauratns.css';
import React,{useEffect,useState} from 'react';
import Background from '../image/Back.jpeg'; 
import Button from '@mui/material/Button';
import {useNavigate,useParams} from 'react-router-dom';
//import Typography from '@mui/material/Typography';
//import FacebookIcon from '@mui/icons-material/Facebook';
//import InstagramIcon from '@mui/icons-material/Instagram';
import { makeStyles } from '@mui/styles';
import { Watch } from "react-loader-spinner";
import Footer from './Footer';

const useStyles = makeStyles({
  font:{
    fontFamily: "'Cairo' !important",
  },
  button:{
    fontFamily: "'Cairo' !important",
    '@media (max-width: 767px)':{ 
      
      width:'31% !important',
      marginTop:'8px !important',
  }
  },
});

function ChooseRestaurant() {
  const {lan} = useParams();
  const [deta, setDeta] = useState([]);
  const [namrR, setNamrR] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  /*useEffect(() => {
    console.log(lan);
  }, [lan]);*/
  let navigate = useNavigate();
  /*const Rest = async (id)=>{
    try {
      const idres =3;
      navigate('/'+lan+'/'+id+'/order');
    } catch (e) {
      console.log('error');
    }
    };
  const Rest2 = async ()=>{
    try {
      const idres =4;
      navigate('/'+lan+'/'+idres+'/order');
    } catch (e) {
      console.log('error');
    }
    };
    const Rest3 = async ()=>{
      try {
        const idres =5;
        navigate('/'+lan+'/'+idres+'/order');
      } catch (e) {
        console.log('error');
      }
      };*/
      useEffect(() => {

        const fetchData = () => {
          return fetch("https://dashboard.oyoonbaghdad.com/api/get/info", {method: 'GET',headers: {'key': 'Plansters2023',}})
                .then((response) => response.json())
                .then((data) => { 
                   setDeta(data.data) ;
                });
        };
        fetchData();
      },[]);
      useEffect(() => {

        const fetchData = () => {
          return fetch("https://dashboard.oyoonbaghdad.com/api/get/restaurants", {method: 'GET',headers: {'key': 'Plansters2023',}})
                .then((response) => response.json())
                .then((data) => { 
                  setNamrR(data.data) ;
                  setIsLoading(true);
                });
        };
        fetchData();
      },[]);
      console.log('deta',deta)
      console.log('NamrR',namrR)
      if (isLoading === false) return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <Watch
            height="80"
            width="80"
            radius="48"
            color="#0C5E5B"
            ariaLabel="watch-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      );

  return (
    <div
      style={{
        backgroundImage: `url(${deta.background_image_url})`,
        backgroundPositionY: "bottom",
        backgroundPositionX: "left",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100vh",
      }}
    >
      <div className="heightImag"></div>
      <div>
        {/* <div className='logo' style={{display: 'flex',justifyContent: 'center',}}>
              <img className='logoImg' src={deta.image_url}  alt='logo'/>
  </div>*/}
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "0px",
            flexWrap: "wrap",
          }}
        >
          {lan === "ar"
            ? namrR.map((namrRObj, index) => (
                <Button
                  key={namrRObj.id}
                  onClick={() =>
                    navigate("/" + lan + "/" + `${namrRObj.id}` + "/categories")
                  }
                  variant="contained"
                  fullWidth
                  sx={{ color: "white", width: "27%", background: "#0C5E5B" }}
                  className={classes.button}
                >
                  {namrRObj.name}
                </Button>
              ))
            : namrR.map((namrRObj, index) => (
                <Button
                  key={namrRObj.id}
                  onClick={() =>
                    navigate("/" + lan + "/" + `${namrRObj.id}` + "/categories")
                  }
                  variant="contained"
                  fullWidth
                  sx={{ color: "white", width: "27%", background: "#0C5E5B" }}
                  className={classes.button}
                >
                  {namrRObj.name_english}
                </Button>
              ))}
        </div>
        {/* <div style={{marginTop: '45px',}}>
              <Typography variant="body2" color="text.secondary" align="center" sx={{  color:'white',}} className={classes.font}>
                جميع الأسعار خاضعة لـ10% ضريبة خدمة
              </Typography>
              <Typography variant="body2" color="text.secondary" align="center" sx={{  color:'white',}} className={classes.font}>
                All prices are subject to 10% service charge
              </Typography>
            </div>
            <div style={{marginTop: '30px',display: 'flex',justifyContent: 'center',}}>
            <a href='https://m.facebook.com/oyoon.baghdad/' style={{textDecoration: 'none',}}><FacebookIcon style={{fontSize: 'x-large',color:'white'}} /></a>
                <a href='https://www.instagram.com/restaurantoyoonbaghdad/' style={{textDecoration: 'none',}}><InstagramIcon style={{fontSize: 'x-large',marginLeft:'10px',color:'white'}}/></a>
      </div>*/}
      </div>
      <Footer data={deta} />
    </div>
  );
}

export default ChooseRestaurant;