import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
// import "./NavbarBack.css";
import { useParams, useNavigate } from "react-router-dom";

function NavbarBack() {
  let { lan } = useParams();

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
    // this.context.router.goBack(-1);

  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar variant="dense" className="container">
          <a
            // to={"/" + lan + "/restauratns"}
             onClick={goBack}
            style={{ textDecoration: "none" }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              style={{ color: "white" }}
            >
              <ArrowBackIcon />
            </IconButton>
          </a>
          <Link
           
            style={{ textDecoration: "none" }}
            onClick={goBack}
          >
            <Typography
              variant="h6"
              color="inherit"
              component="div"
              style={{ color: "white" }}
            >
              Oyoon Baghdad
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default NavbarBack;
