import './Home.css';
import React,{useEffect,useState} from 'react';
import Background from '../image/Back.jpeg'; 
import Button from '@mui/material/Button';


import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Box } from "@mui/material";




import {useNavigate} from 'react-router-dom';
//import Typography from '@mui/material/Typography';
//import FacebookIcon from '@mui/icons-material/Facebook';
//import InstagramIcon from '@mui/icons-material/Instagram';
import { makeStyles } from '@mui/styles';
import { Watch } from "react-loader-spinner";
import Footer from './Footer';



const useStyles = makeStyles({
  font:{
    fontFamily: "'Cairo' !important",
  
},
 

  
});




function Home() {
  const [deta, setDeta] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  let navigate = useNavigate();
  const Lang = async ()=>{
    try {
      const lan = 'ar';
      navigate('/'+lan+'/restauratns');
    } catch (e) {
      console.log('error');
    }
    };
    const Lang1 = async ()=>{
      try {
        const lan = 'en';
        navigate('/'+lan+'/restauratns');
      } catch (e) {
        console.log('error');
      }
      };

 

  useEffect(() => {

    const fetchData = () => {
      return fetch("https://dashboard.oyoonbaghdad.com/api/get/info", {method: 'GET',headers: {'key': 'Plansters2023',}})
            .then((response) => response.json())
            .then((data) => { 
               setDeta(data.data) ;
               setIsLoading(true);
            });
    };
    fetchData();
  },[]);
  console.log('deta',deta)
  if (isLoading === false) return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <Watch
        height="80"
        width="80"
        radius="48"
        color="#0C5E5B"
        ariaLabel="watch-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
  return (
    <div
      style={{
        backgroundImage: `url(${deta.background_image_url})`,
        backgroundPositionY: "bottom",
        backgroundPositionX: "left",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100vh",
      }}
    >
      <div className="heightImag"></div>
      <div>
        {/* <div className='logo' style={{display: 'flex',justifyContent: 'center',}}>
              <img className='logoImg' src={deta.image_url}  alt='logo'/>
  </div>*/}
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "0px",
            position: "absolute",
            width: "100%",
            bottom: "65px",
          }}
        >
          <Button
            onClick={Lang}
            variant="contained"
            fullWidth
            sx={{ color: "white", width: "40%", background: "#0C5E5B" }}
            className={classes.font}
          >
            عربي
          </Button>
          <Button
            onClick={Lang1}
            variant="contained"
            fullWidth
            sx={{ color: "white", width: "40%", background: "#0C5E5B" }}
            className={classes.font}
          >
            English
          </Button>
        </div>
        {/*<div style={{marginTop: '45px',}}>
              <Typography variant="body2" color="text.secondary" align="center" sx={{  color:'white',}} className={classes.font}>
                جميع الأسعار خاضعة لـ10% ضريبة خدمة
              </Typography>
              <Typography variant="body2" color="text.secondary" align="center" sx={{  color:'white',}} className={classes.font}>
                All prices are subject to 10% service charge
                
              </Typography>
            </div>
            <div style={{marginTop: '30px',display: 'flex',justifyContent: 'center',}}>
                <a href='https://m.facebook.com/oyoon.baghdad/' style={{textDecoration: 'none',}}><FacebookIcon style={{fontSize: 'x-large',color:'white'}} /></a>
                <a href='https://www.instagram.com/restaurantoyoonbaghdad/' style={{textDecoration: 'none',}}><InstagramIcon style={{fontSize: 'x-large',marginLeft:'10px',color:'white'}}/></a>
</div>*/}
      </div>
      <Footer data={deta} />
    </div>
  );
}

export default Home;