import './Menus.css';
import "./SlideMenu.css";
import React,{useEffect,useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {  CardActionArea,} from '@mui/material';
import Box from '@mui/material/Box';
import {useParams} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Watch } from "react-loader-spinner";
import imageMenu from '../image/15022-NPYB8O.jpg';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import  IconButton  from '@mui/material/IconButton';
//import Modal from './Modal';
import { Link } from "react-router-dom";


const useStyles = makeStyles({
  dishImage: {
   '@media (min-width: 100px)':{ 
      width: '100px !important',
      height: '100px !important',
      borderRadius: '4px',
      objectFit: 'cover'
    }
  },
  
   cardActionArea:{
       '@media (min-width: 100px)':{ 
        display: 'flex !important',
        flexDirection: 'row-reverse',
        paddingRight: '16px !important',
        boxShadow: 'rgba(0, 0, 0, 0.15) 2px 2px 10px'
      }

    },
    cardContent:{
     
     '@media (min-width: 100px)':{ 
        width: '85%'
      }
    },
    muiPaper:{
      margin: '0 auto',
    marginTop:' 15px',
    maxWidth: '90% !important',
    boxShadow:'rgba(0, 0, 0, 0.15) 2px 2px 10px !important',
    '@media (min-width: 767px)':{ 
      
        margin: '0 auto',
        marginTop: '20px'
    }
  },
  font:{
    fontFamily: "'Cairo' !important",
  },
  fontsSize:{
    fontFamily: "'Cairo' !important",
    '@media (max-width: 767px)':{ 
      
      fontSize:'20px !important',
  }
  },

  stylesBoxs:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:'40%',
    bgcolor: 'white',
    //border: '0px solid #000',
    boxShadow: 24,
    borderRadius: '8px',
    outline:'none',
    border: 'none',
    '@media (max-width: 767px)':{ 
      
      width:'80%',
  }
    
  },
  customizedButton: {
    position: 'absolute !important' ,
    left: '95%',
    top: '-9%',
    backgroundColor: 'lightgray !important',
    color: 'gray !important',
    '@media (min-width: 1200px)':{ 
      position: 'absolute !important' ,
      left: '97%',
      top: '-7%',
      backgroundColor: 'lightgray !important',
      color: 'gray !important',
  }
  }
 
});


function Menus() {
  //const one = 1;
  const [food, setFood] = useState([]);
  const [srcImage, setSrcImage] = useState();
  const [cardName, setCardName] = useState();
  const [cardDescription, setCardDescription] = useState();
  const [cardPrice, setCardPrice] = useState();



  const {lan,idres} = useParams();
  const [idmenu,setIdmenu] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [open, setOpen] =useState(false);
  //const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  

  
  useEffect(() => {
    
    let formData = new FormData();

formData.append('restaurant_id', Number(idres));

    const fetchData = () => {
      return fetch("https://dashboard.oyoonbaghdad.com/api/get/categories", {method: 'POST',body:formData,headers: {'key': 'Plansters2023'}})
            .then((response) => response.json())
            .then((data) => { 
              setFood(data.data) ;

              // start update idMenu

              const currentUrl = window.location.href;
              const urlObject = new URL(currentUrl);
              const searchParams = urlObject.searchParams;

              if (searchParams.has('cat')) {
              const result = data.data.filter(object => object.id == searchParams.get('cat'));
                setIdmenu(result);
              }else{
                  const item = [data.data[0]] ;

                   setIdmenu(item);
              }

              // end update idMenu




              setIsLoading(true);
              
             
               
            });
    };
    fetchData();
  },[]);


  // useEffect(() => {
  //   Test();
  // }, [food]);


  function Test(){

    const currentUrl = window.location.href;
    const urlObject = new URL(currentUrl);
    const searchParams = urlObject.searchParams;

    if (searchParams.has('cat')) {
    const resultt = food.filter(test => test.id == searchParams.get('cat'));
      setIdmenu(resultt);
    }
    
  }

  function myFilter(id) {
    const result = food.filter(test => test.id == id);
    setIdmenu(result);
    console.log('result',result)
    

    // Get the current URL
const currentUrl = window.location.href;

// Parse the URL
const urlObject = new URL(currentUrl);

// Get the current search parameters
const searchParams = urlObject.searchParams;

// Update an existing query parameter
const keyToUpdate = 'cat'; // Replace 'cat' with the parameter key you want to update
const newValue = id; // Replace 'newCatValue' with the new value

// Check if the key exists in the current search parameters
if (searchParams.has(keyToUpdate)) {
  // Remove the existing parameter
  searchParams.delete(keyToUpdate);
}

// Add the updated parameter with the new value
searchParams.append(keyToUpdate, newValue);

// Serialize the modified URL back to a string
const modifiedUrl = urlObject.toString();

// Use pushState to update the URL without refreshing the page
window.history.pushState(null, null, modifiedUrl);





    //return result;
  }

  function models(src,name,description,price){
    setSrcImage(src);
    setCardName(name);
    setCardDescription(description);
    setCardPrice(price);

    setOpen(true);
  }

  if (isLoading === false) return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <Watch
        height="80"
        width="80"
        radius="48"
        color="#0C5E5B"
        ariaLabel="watch-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
  return (
    <div className="container">
      <Box
        sx={{ flexGrow: 1, marginBottom: "30px" }}
        className="cards container0"
      >
        {/*<div className="slideImg">*/}
        {food.length > 0 ? (
          <div className="slideImg">
            {lan === "ar"
              ? food.map((foodObj, index) => (
                  <div
                    className="containers"
                    key={index}
                    onClick={() => myFilter(foodObj.id)}
                  >
                    <img
                      src={foodObj.image_url}
                      alt="menu1"
                      style={{ marginLeft: "15px" }}
                    />
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      align="center"
                      sx={{ fontSize: "inherit" }}
                    >
                      {foodObj.name}
                    </Typography>
                  </div>
                ))
              : food.map((foodObj, index) => (
                  <div
                    className="containers"
                    key={index}
                    onClick={() => myFilter(foodObj.id)}
                  >
                    <img
                      src={foodObj.image_url}
                      alt="menu1"
                      style={{ marginLeft: "15px" }}
                    />
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      align="center"
                      sx={{ fontSize: "inherit" }}
                    >
                      {foodObj.name_english}
                    </Typography>
                  </div>
                ))}
          </div>
        ) : null}

        {/*</div>*/}
        <br />
        {food.length > 0 ? (
          <div style={{ marginTop: "-15px" }}>
            {lan === "ar" ? (
              <Typography
                gutterBottom
                variant="h4"
                component="div"
                align="center"
                className={classes.fontsSize}
              >
                {idmenu[0].name}
              </Typography>
            ) : (
              <Typography
                gutterBottom
                variant="h4"
                component="div"
                align="center"
                className={classes.fontsSize}
              >
                {idmenu[0].name_english}
              </Typography>
            )}
          </div>
        ) : null}
        {food.length > 0 ? (
          <div>
            {lan === "en" ? (
              <div>
                {idmenu &&
                  idmenu.length > 0 &&
                  idmenu.map((idmenuObj, index) => (
                    <div className="CardFood" key={index}>
                      {idmenuObj.dishes &&
                        idmenuObj.dishes.map((dish, index) => (
                          <Link
                            to="/en/dish"
                            state={dish}
                            style={{ textDecoration: "none" }}
                          >
                            <Card className={classes.muiPaper} key={index}>
                              <CardActionArea
                                className={classes.cardActionArea}
                                onClick={() =>
                                  models(
                                    dish.image_url,
                                    dish.name_english,
                                    dish.description_english,
                                    dish.price
                                  )
                                }
                              >
                                <CardMedia
                                  className={classes.dishImage}
                                  component="img"
                                  height="140"
                                  image={
                                    dish.image_url ? dish.image_url : imageMenu
                                  }
                                  alt="green iguana"
                                />
                                <CardContent className={classes.cardContent}>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                    className={classes.fontsSize}
                                    sx={{ color: "#0C5E5B" }}
                                  >
                                    {dish.name_english}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    className={classes.font}
                                  >
                                    {dish.description_english}
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    color="div"
                                    className={classes.font}
                                    sx={{ color: "#0C5E5B" }}
                                  >
                                    {dish.price} IQD
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </Link>
                        ))}
                    </div>
                  ))}
              </div>
            ) : (
              <div>
                {idmenu &&
                  idmenu.length > 0 &&
                  idmenu.map((idmenuObj, index) => (
                    <div className="CardFood" key={index}>
                      {idmenuObj.dishes &&
                        idmenuObj.dishes.map((dish, index) => (
                          <Link
                            to="/ar/dish"
                            state={dish}
                            style={{ textDecoration: "none" }}
                          >
                            <Card className={classes.muiPaper} key={index}>
                              <CardActionArea
                                className={classes.cardActionArea}
                                onClick={() =>
                                  models(
                                    dish.image_url,
                                    dish.name,
                                    dish.description,
                                    dish.price
                                  )
                                }
                              >
                                <CardMedia
                                  className={classes.dishImage}
                                  component="img"
                                  height="140"
                                  image={
                                    dish.image_url ? dish.image_url : imageMenu
                                  }
                                  alt="green iguana"
                                />
                                <CardContent className={classes.cardContent}>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                    align="right"
                                    className={classes.fontsSize}
                                    sx={{ color: "#0C5E5B" }}
                                  >
                                    {dish.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    align="right"
                                    className={classes.font}
                                  >
                                    {dish.description}
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    color="div"
                                    className={classes.font}
                                    sx={{ color: "#0C5E5B" }}
                                  >
                                    {dish.price} IQD
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </Link>
                        ))}
                    </div>
                  ))}
              </div>
            )}
          </div>
        ) : null}
        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.stylesBoxs}>
            <IconButton
              className={classes.customizedButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>

            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  style={{ height: "50vh" }}
                  image={srcImage ? srcImage : imageMenu}
                  alt="green iguana test"
                />
                <CardContent>
                  {lan === "ar" ? (
                    <h2
                      id="cartNameMenu"
                      style={{
                        color: "#0C5E5B",
                        fontFmily: "Cairo",
                        fontWeight: "400",
                        textAlign: "right",
                        fontFamily: "Cairo",
                      }}
                    >
                      {cardName}
                    </h2>
                  ) : (
                    <h2
                      id="cartNameMenu"
                      style={{
                        color: "#0C5E5B",
                        fontFmily: "Cairo",
                        fontWeight: "400",
                        textAlign: "left",
                      }}
                    >
                      {cardName}
                    </h2>
                  )}
                  {lan === "ar" ? (
                    <span
                      id="cartPriceMenu"
                      style={{
                        color: "#0C5E5B",
                        fontFmily: "Cairo",
                        fontWeight: "600",
                        textAlign: "right",
                        fontFamily: "Cairo",
                        display:"block"
                      }}
                    >
                      {cardPrice} IQD
                    </span>
                  ) : (
                    <span
                      id="cartPriceMenu"
                      style={{
                        color: "#0C5E5B",
                        fontFmily: "Cairo",
                        fontWeight: "600",
                      }}
                    >
                      {cardPrice} IQD
                    </span>
                  )}

                  <p style={{ color: "#00000099" }}>{cardDescription}</p>
                </CardContent>
              </CardActionArea>
            </Card>
          </Box>
        </Modal> */}
      </Box>
    </div>
  );
}

export default Menus;

//<Link to={`/${lan}/${idres}/card/${one}`} style={{textDecoration: 'none',}}>




