import './Categories.css';
import NavbarOrder from './NavbarOrder';
import Menus from './Menus';
import Footer from './Footer';
import React, { useEffect, useState } from "react";

 



function Order() {

   const [deta, setDeta] = useState([]);

  
  useEffect(() => {
    const fetchData = () => {
      return fetch("https://dashboard.oyoonbaghdad.com/api/get/info", {
        method: "GET",
        headers: { key: "Plansters2023" },
      })
        .then((response) => response.json())
        .then((data) => {
          setDeta(data.data);
          // setIsLoading(true);
        });
    };
    fetchData();
  }, []);

  return (
    <div>
      <NavbarOrder />
      <Menus />
      <Footer data={deta} />
    </div>
  );
}

export default Order;