import './App.css';
import { Routes , Route ,Navigate  } from "react-router-dom";
import Home from './components/Home';
import Categories from './components/Categories';
import Restauratns from './components/Restauratns';
//import Menu from './components/Menu';
import { createTheme , ThemeProvider} from "@mui/material/styles";
import Dish from './components/Dish';


function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#0C5E5B",
      },
    },
  });

  return (
     <>
     <ThemeProvider theme={theme}>
        <Routes>
            <Route exact path="/" element={ <Home/> } />
            <Route exact path="/:lan/restauratns" element={ <Restauratns/> } />
            <Route exact path="/:lan/:idres/categories" element={ <Categories/> } />
             <Route exact path="/:lan/dish" element={ <Dish/> } />
            {/*<Route exact path="/:lan/:idres/categorie/:id/:id1" element={ <Menu/> } />*/}
            <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
     </ThemeProvider>
    </>
  );
}

export default App;
